<template>
  <div class="PlaceOrder">
    <van-sticky style="width: 100%" v-if="!isFromApp">
      <van-nav-bar
        :title="`${isParking() ? 'TICKET' : 'ORDER'} DETAILS`"
        left-arrow
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="#ffffff" size="16pt" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div
      class="content"
      :style="{ height: isFromApp ? '100%' : 'calc(100% - 35pt)' }"
    >
      <template v-if="!isFromApp">
        <van-cell-group inset v-if="order">
          <van-cell
            class="bold-divider-cell"
            style="font-weight: bold"
            :title="`${filterText('Order')} #`"
            :value="order.orderId"
          />
          <van-cell
            style="padding: 10px 16px 2px"
            :title="`${filterText('Meal')} Type`"
            :value="order.mealPeriod"
            v-if="order.roomNum !== 'Parking'"
          />
          <van-cell
            style="padding: 2px 16px"
            :title="`${filterText('Order')} Type`"
            :value="order.orderType"
          />
          <van-cell
            style="padding: 2px 16px"
            title="Payment Type"
            :value="order.payMethod"
          />
          <template v-if="order.chargeRoom">
            <van-cell
              style="padding: 2px 16px"
              title="Room #"
              :value="order.chargeRoom"
            />
            <van-cell
              style="padding: 2px 16px"
              title="Table #"
              :value="order.roomNum"
            />
          </template>
          <van-cell
            v-else
            style="padding: 2px 16px"
            title="Room #"
            :value="order.roomNum"
          />
          <van-cell
            style="padding: 2px 16px"
            :title="`${filterText('Order')} Time`"
            :value="'Today ' + moment(order.orderTime).format('h:mm A')"
          />
          <van-cell
            class="sp-van-cell"
            style="padding: 2px 16px"
            title="Requested Time"
            :value="order.time"
            v-if="order.roomNum !== 'Parking'"
          />
          <van-cell
            class="bold-divider-cell"
            style="padding: 2px 16px 10px"
            title="Number Of Guests"
            :value="order.guestNum"
            v-if="order.roomNum !== 'Parking'"
          />

          <van-cell
            style="padding: 2px 16px"
            title="Last Name"
            :value="order.lastName"
            v-if="order.roomNum === 'Parking'"
          />
          <van-cell
            style="padding: 2px 16px"
            title="Make/Color"
            :value="order.make"
            v-if="order.roomNum === 'Parking'"
          />
          <van-cell
            style="padding: 2px 16px"
            title="License Plate"
            :value="order.license"
            v-if="order.roomNum === 'Parking'"
          />
          <van-cell
            style="padding: 2px 16px"
            title="From"
            :value="
              order.shoppingFood[0].fromDate +
              ' ' +
              order.shoppingFood[0].fromTime
            "
            v-if="order.roomNum === 'Parking'"
          />
          <van-cell
            class="bold-divider-cell"
            style="padding: 2px 16px 10px"
            title="To"
            :value="
              order.shoppingFood[0].toDate + ' ' + order.shoppingFood[0].toTime
            "
            v-if="order.roomNum === 'Parking'"
          />

          <van-cell
            class="bold-divider-cell"
            v-if="order.remarks"
            title="Additional Comments"
          >
            <template #label>
              <p class="" style="font-size: 15px; color: #01688a">
                {{ order.remarks }}
              </p>
            </template>
          </van-cell>
          <!-- :title="food.num + ' ' + getSize(food) + ' * ' + food.name" -->
          <van-cell
            v-for="(food, index) in order.shoppingFood"
            :key="food.id"
            :title="food.num + ' * ' + food.name"
            :value="'$' + food.allPrice.toFixed(2)"
            :style="{
              padding:
                order.shoppingFood.length === 1
                  ? '10px 16px'
                  : index === 0
                  ? '10px 16px 5px'
                  : index === order.shoppingFood.length - 1 && !order.promoFee
                  ? '0px 16px 10px'
                  : '0px 16px 5px',
            }"
            :class="{
              'bold-divider-cell':
                index === order.shoppingFood.length - 1 && !order.promoFee,
              shoppingFood: true,
            }"
          >
            <template #label>
              <div>
                <div
                  v-for="(sideDish, index) in food.sideDishList"
                  :key="index"
                >
                  <div v-for="option in sideDish.optionList" :key="option.id">
                    <div
                      style="padding-top: 3px"
                      v-if="isShowOption(food, option.id)"
                      :key="option.id"
                    >
                      <span
                        style="font-size: 15px; color: #484848"
                        class="word-break"
                        >-- ({{ sideDish.title }}) {{ option.name }}</span
                      >
                    </div>
                  </div>
                </div>
                <div v-if="food.remark" style="font-size: 15px; color: #01688a">
                  (Note) {{ food.remark }}
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell
            v-if="order.promoFee"
            :title="'Discount: ' + order.promoFeeName"
            :value="'-$' + order.promoFee.toFixed(2)"
            style="padding: 0px 16px 10px"
            class="bold-divider-cell shoppingFood"
          />
          <van-cell
            style="padding: 10px 16px 2px"
            title="Subtotal"
            :value="'$' + order.subTotal.toFixed(2)"
          />
          <van-cell
            v-for="(fee, index) in order.feeList"
            :key="fee.id"
            :title="fee.fee"
            :value="'$' + fee.value.toFixed(2)"
            style="font-size: 15px"
            :style="{
              padding:
                index === order.feeList.length - 1
                  ? '2px 16px 10px'
                  : '2px 16px',
              fontSize: '15px',
            }"
            :class="{
              'bold-divider-cell': index === order.feeList.length - 1,
              feeList: true,
              shoppingFood: true,
            }"
          />
          <van-cell
            v-if="order.addTip"
            :title="'Additional Tip'"
            :value="'$' + order.addTip.toFixed(2)"
            style="padding: 10px 16px"
            class="bold-divider-cell shoppingFood"
          />
          <van-cell
            :class="{ 'bold-divider-cell': order.remarks }"
            style="font-weight: bold"
            title="TOTAL"
            :value="'$' + order.total.toFixed(2)"
          />
        </van-cell-group>
      </template>
      <template v-else>
        <van-cell-group inset v-if="orderList.length > 0">
          <van-cell
            class="bold-divider-cell"
            style="font-weight: bold"
            title="Order #"
            :value="ticketId"
          />
          <van-cell
            style="padding: 2px 16px"
            title="Order Type"
            :value="getOrderTypeName2(orderList[0].orderType)"
          />
          <van-cell
            style="padding: 2px 16px"
            title="Payment Type"
            :value="orderList[0].payMethod"
          />
          <template v-if="orderList[0].chargeRoom">
            <van-cell
              style="padding: 2px 16px"
              title="Room #"
              :value="orderList[0].chargeRoom"
            />
            <van-cell
              style="padding: 2px 16px"
              title="Table #"
              :value="orderList[0].roomNum"
            />
          </template>
          <van-cell
            v-else
            style="padding: 2px 16px"
            title="Room #"
            :value="orderList[0].roomNum"
          />
          <van-cell
            style="padding: 2px 16px 2px"
            title="Last Name"
            :value="orderList[0].lastName"
          />
          <van-cell
            style="padding: 2px 16px 2px"
            title="Number Of Guests"
            :value="orderList[0].guestNum"
          />
          <van-cell
            v-if="orderList[0].remarks"
            class=""
            title="Additional Comments"
          >
            <template #label>
              <p
                class=""
                style="font-size: 15px; color: #01688a; margin-bottom: 0"
              >
                {{ orderList[0].remarks }}
              </p>
            </template>
          </van-cell>
        </van-cell-group>
        <template v-for="order in orderList">
          <div :key="order.orderId">
            <div style="text-align: right; font-size: 16px; color: #000000">
              <span>{{ order.orderId }}</span>
            </div>
            <van-cell-group inset>
              <van-cell
                class="bold-divider-cell"
                title="Requested Time"
                :value="order.time"
              />
              <van-cell
                style="padding: 10px 16px 2px"
                title="Meal Type"
                :value="order.mealPeriod"
              />
              <van-cell
                v-for="(food, index) in order.shoppingFood"
                :key="food.id"
                :title="food.num + ' ' + getSize(food) + ' * ' + food.name"
                :value="'$' + food.allPrice.toFixed(2)"
                :style="{
                  padding:
                    order.shoppingFood.length === 1
                      ? '10px 16px'
                      : index === 0
                      ? '10px 16px 5px'
                      : index === order.shoppingFood.length - 1 &&
                        !order.promoFee
                      ? '0px 16px 10px'
                      : '0px 16px 5px',
                }"
                :class="{
                  'bold-divider-cell':
                    index === order.shoppingFood.length - 1 && !order.promoFee,
                  shoppingFood: true,
                }"
              >
                <template #label>
                  <div>
                    <div
                      v-for="(sideDish, index) in food.sideDishList"
                      :key="index"
                    >
                      <div
                        v-for="option in sideDish.optionList"
                        :key="option.id"
                      >
                        <div
                          style="padding-top: 3px"
                          v-if="isShowOption(food, option.id)"
                          :key="option.id"
                        >
                          <span
                            style="font-size: 15px; color: #484848"
                            class="word-break"
                            >-- ({{ sideDish.title }}) {{ option.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="food.remark"
                      style="font-size: 15px; color: #01688a"
                    >
                      (Note) {{ food.remark }}
                    </div>
                  </div>
                </template>
              </van-cell>
              <van-cell
                v-if="order.promoFee"
                :title="'Discount: ' + order.promoFeeName"
                :value="'-$' + order.promoFee.toFixed(2)"
                style="padding: 0px 16px 10px"
                class="bold-divider-cell shoppingFood"
              />
              <van-cell
                style="padding: 10px 16px 2px"
                title="Subtotal"
                :value="'$' + order.subTotal.toFixed(2)"
              />
              <van-cell
                v-for="(fee, index) in order.feeList"
                :key="fee.id"
                :title="fee.fee"
                :value="'$' + fee.value.toFixed(2)"
                style="font-size: 15px"
                :style="{
                  padding:
                    index === order.feeList.length - 1
                      ? '2px 16px 10px'
                      : '2px 16px',
                  fontSize: '15px',
                }"
                :class="{
                  'bold-divider-cell': index === order.feeList.length - 1,
                  feeList: true,
                  shoppingFood: true,
                }"
              />
              <van-cell
                v-if="order.addTip"
                :title="'Additional Tip'"
                :value="'$' + order.addTip.toFixed(2)"
                style="padding: 10px 16px"
                class="bold-divider-cell shoppingFood"
              />
              <van-cell
                :class="{ 'bold-divider-cell': order.remarks }"
                style="font-weight: bold"
                title="TOTAL"
                :value="'$' + order.total.toFixed(2)"
              />
            </van-cell-group>
          </div>
        </template>
      </template>

      <!--      <van-cell-group inset v-if="isFromApp">-->
      <!--        <van-cell class="bold-divider-cell" style="font-weight: bold" title="Order #" :value="order.requestId"/>-->
      <!--        <van-cell style="padding: 10px 16px 2px" title="Meal Type" :value="order.foodDetail.mealPeriod"/>-->
      <!--        <van-cell style="padding: 2px 16px" title="Order Type" :value="order.foodDetail.orderType"/>-->
      <!--        <van-cell style="padding: 2px 16px" title="Payment Type" :value="order.foodDetail.payMethod"/>-->
      <!--        <van-cell style="padding: 2px 16px" title="Room #" :value="order.foodDetail.roomNum"/>-->
      <!--        <van-cell-->
      <!--          style="padding: 2px 16px"-->
      <!--          title="Order Time"-->
      <!--          :value="order.foodDetail.orderTime"-->
      <!--        />-->
      <!--        <van-cell class="sp-van-cell" style="padding: 2px 16px" :title="order.foodDetail.orderType + ' Time'" :value="order.time"/>-->
      <!--        <van-cell class="bold-divider-cell" style="padding: 2px 16px 10px" title="Number Of Guests"-->
      <!--                  :value="order.foodDetail.guestNum"/>-->
      <!--        <van-cell-->
      <!--          v-for="(food,index) in order.foodDetail.shoppingFood"-->
      <!--          :key="food.id"-->

      <!--          :title="food.num + ' ' + getSize(food) + ' * ' + food.name"-->
      <!--          :value="'$' + food.allPrice.toFixed(2)"-->
      <!--          :style="{padding:index === 0?'10px 16px 5px':'0px 16px 5px'}"-->
      <!--          :class="{'bold-divider-cell':index===order.foodDetail.shoppingFood.length-1,'shoppingFood':true}"-->
      <!--        >-->
      <!--          <template #label>-->
      <!--            <div>-->
      <!--              <div v-for="(sideDish,index) in food.sideDishList" :key="index">-->
      <!--                <div v-for="option in sideDish.optionList" :key="option.id">-->
      <!--                  <div style="padding-top: 3px" v-if="isShowOption(food, option.id)" :key="option.id">-->
      <!--                      <span style="font-size: 15px;color: #484848;" class="word-break">&#45;&#45; ({{ sideDish.title }}) {{-->
      <!--                          option.name-->
      <!--                        }}</span>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div v-if="food.remark" style="font-size: 15px;color: #01688a">-->
      <!--                (Note) {{ food.remark }}-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </template>-->
      <!--        </van-cell>-->
      <!--        <van-cell style="padding: 10px 16px 2px" title="Subtotal"-->
      <!--                  :value="'$' + order.foodDetail.subTotal.toFixed(2)"/>-->
      <!--        <van-cell-->
      <!--          v-for="(fee,index) in order.foodDetail.feeList"-->
      <!--          :key="fee.id"-->
      <!--          :title="fee.fee"-->
      <!--          :value="'$' + fee.value.toFixed(2)"-->
      <!--          style="font-size: 15px"-->
      <!--          :style="{padding:index === order.foodDetail.feeList.length - 1?'2px 16px 10px':'2px 16px',fontSize:'15px'}"-->
      <!--          :class="{'bold-divider-cell':index===order.foodDetail.feeList.length-1,'feeList':true,'shoppingFood':true,}"-->
      <!--        />-->
      <!--        <van-cell :class="{'bold-divider-cell':order.foodDetail.remarks}" style="font-weight: bold" title="TOTAL"-->
      <!--                  :value="'$' + order.foodDetail.total.toFixed(2)"/>-->
      <!--        <van-cell v-if="order.foodDetail.remarks" class="" title="Additional Comments">-->
      <!--          <template #label>-->
      <!--            <p class="" style="font-size: 15px;color: #01688a">-->
      <!--              {{ order.foodDetail.remarks }}-->
      <!--            </p>-->
      <!--          </template>-->
      <!--        </van-cell>-->
      <!--      </van-cell-group>-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import moment from "moment-timezone";

export default {
  name: "Order",
  components: {},
  data() {
    return {
      url: {
        getFee: "/adminApi/foodOrder/getFeeList",
        logMessage: "/adminApi/elavon/logMessage",
        getOrderDetail:
          "/api/common/hotel/data/order_info/pc/sub_order_list_all/",
        getOrderType: "/adminApi/omnivore/orderTypes",
      },
      order: null,
      specialInstructions: [],
      isFromApp: false,
      orderId: "",
      htOrder: {},
      subOrderList: [],
      subOrderId: "",
      orderList: [],
      ticketId: "",
      orderTypeList: JSON.parse(localStorage.getItem("orderTypeList")) || [],

      locationType: "",
    };
  },
  computed: {
    ...mapGetters({
      orderData: "order", //购物车中的餐品
    }),
  },
  created() {
    // 从 APP 过来
    if (this.$route.query.device) {
      this.deviceLog("app进入订单详情页面");
      this.orderId = this.$route.query.id;
      localStorage.setItem("HTTOKEN", this.$route.query.token);
      this.subOrderId = this.$route.query.subId;
      if (this.$route.query.device == "phone") {
        this.isFromApp = true;
      }
      this.getOrderDetail();
    } else {
      this.locationType = this.$route.query.location;
      if (this.orderData.length == 0) {
        // this.$router.replace("/");
        return;
      }
      this.order = this.orderData.find((item) => {
        return item.id == this.$route.query.id;
      });
      if (this.orderTypeList == []) {
        this.getOrderType();
      } else {
        this.getOrderTypeName();
      }
      this.getSpecialInstructions();
    }
  },
  methods: {
    moment,
    filterText(text) {
      if (this.order.roomNum === "Parking") {
        return "Ticket";
      }
      return text;
    },
    deviceLog(message) {
      // getAction(`${this.url.logMessage}?message=${message}`).then((r) => {});
    },
    getOrderType() {
      // console.log('getorderlist')
      getAction(`${this.url.getOrderType}?hotelId=${this.hotelId}`).then(
        (res) => {
          this.orderTypeList = res.data;
          this.getOrderTypeName();
        }
      );
    },
    getOrderTypeName() {
      let id = this.order.orderType;
      let target = this.orderTypeList.find((item) => {
        return item.id == id;
      });
      if (target) {
        this.order.orderType = target.displayName;
      }
    },
    getOrderTypeName2(id) {
      let target = this.orderTypeList.find((item) => {
        return item.id == id;
      });
      if (target) {
        return target.displayName;
      } else {
        return id;
      }
    },
    getOrderDetail() {
      this.orderList = [];
      postAction(`${this.url.getOrderDetail}${this.orderId}`).then((res) => {
        this.deviceLog("开始请求订单详情数据");
        for (let i = 0; i < res.data.subOrderList.length; i++) {
          let subOrder = res.data.subOrderList[i];
          if (subOrder.foodDetail) {
            let order = JSON.parse(subOrder.foodDetail);
            order.orderId =
              subOrder.requestId.substring(0, 8) +
              "-" +
              subOrder.requestId.substring(8) +
              "-" +
              subOrder.requestIndex;
            this.ticketId =
              subOrder.requestId.substring(0, 8) +
              "-" +
              subOrder.requestId.substring(8);
            this.orderList.push(order);
          }
        }
        this.hotelId = res.data.htOrder.hotelId;
        getAction(`${this.url.getOrderType}?hotelId=${this.hotelId}`).then(
          (r) => {
            this.deviceLog("开始请求订单类型列表");
            this.orderTypeList = r.data;
          }
        );
        // console.log(this.orderList);
        // this.orderList[0].remarks = "1111111";
        // this.getSpecialInstructions()
      });
    },
    getSize(item) {
      if (item.isFull) {
        if (item.fullName) {
          return "(" + item.fullName + ")";
        } else {
          return "";
        }
      } else {
        if (item.halfName) {
          return "(" + item.halfName + ")";
        } else {
          return "";
        }
      }
    },
    onClickLeft() {
      history.back();
    },
    getSpecialInstructions() {
      this.specialInstructions = this.order.shoppingFood.map((item) => {
        if (item.remark != null) {
          return item.remark;
        }
      });
      this.specialInstructions = this.specialInstructions.filter(
        (item) => item
      );
    },
    isParking() {
      if (this.locationType != null && this.locationType.indexOf('Parking') > -1) {
        return true;
      }
      return false;
    },    
    isShowOption(food, id) {
      for (let i = 0; i < food.foods.length; i++) {
        const element = food.foods[i];
        if (element.checked.indexOf(id) != -1) {
          return true;
        }
        // if (element.type == "Radio") {
        //   if (element.checked == id) {
        //     return true;
        //   }
        // } else {
        //   if (element.checked.indexOf(id) != -1) {
        //     return true;
        //   }
        // }
      }

      if (id == food.drinksChildrenChecked) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.PlaceOrder {
  .word-break {
    word-break: normal;
  }

  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
    }
  }

  /deep/ .van-grid-item__content {
    background: #f5f4f4;
    padding: 17pt 3.75pt 7.5pt;
  }

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .content {
    width: calc(100% - 12pt);
    height: calc(100% - 35pt);
    padding: 10pt;
    overflow: auto;

    /deep/ .van-cell-group--inset {
      margin: 0;
      margin-top: 10pt;
      margin-bottom: 10px;

      .food-cell {
        .van-cell__title {
          flex-grow: 2;
        }

        .van-cell__value {
          flex-grow: 1;
        }
      }
    }

    /deep/ .van-cell__title {
      text-align: left;
      font-size: 12pt;
      font-family: PingFangSC-Semibold, PingFang SC;
      //line-height: 24pt;
      color: #2b2e36;
      word-break: normal;
    }

    /deep/ .van-cell__value {
      font-size: 12pt;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #636464;
      word-break: normal;
    }

    /deep/ .van-cell__label {
      padding-left: 15pt;
      margin-top: 0;
    }
  }

  .bold-divider-titel-cell {
    &:after {
      position: absolute;
      content: "";
      border-bottom: 4px solid #dfdfdf;
    }
  }

  .bold-divider-cell {
    &:after {
      position: absolute;
      content: "";
      border-bottom: 3px solid #dfdfdf;
    }
  }

  .divider {
    height: 2px;
    width: calc(100% - 20pt);
    background-color: #dfdfdf;
    margin-left: 10pt;
  }

  .bold-divider-total-cell {
    position: relative;

    /deep/ .van-cell__title {
      font-weight: bold;
    }

    /deep/ .van-cell__value {
      font-weight: bold;
    }
  }

  .shoppingFood {
    /deep/ .van-cell__title {
      font-size: 16px;
      width: 75%;
      line-height: 20px;
      flex: none;
    }

    /deep/ .van-cell__value {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .feeList {
    /deep/ .van-cell__title {
      font-size: 15px;
      color: #484848;
    }

    /deep/ .van-cell__value {
      font-size: 15px;
      color: #484848;
    }
  }

  .remark {
    font-size: 10pt;
    padding-left: 15pt;
    line-height: 12pt;
    margin-bottom: 0;
    margin: 3pt 0;
  }

  .sp-van-cell {
    /deep/ .van-cell__title {
      width: 35%;
      flex: none;
    }

    /deep/ .van--cell__value {
      width: 65%;
      flex: none;
    }
  }
}
</style>
